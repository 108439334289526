<div
  id="container"
  style="
    background-color: #1b222b;
    display: flex;
    gap: 59.7%;
    flex-shrink: 2;
    width: 100%;
    flex-wrap: wrap-reverse;
  "
>
  <div id="headerimg" style="display: inline-flex">
    <img [src]="headerImg" height="68px" style="flex-basis: 74px" />
  </div>
  <div style="display: inline-flex; flex-basis: auto" id="userButton">
    <button [matMenuTriggerFor]="menu" mat-button>
      <mat-icon
        style="
          font-size: 52px;
          width: fit-content;
          height: fit-content;
          color: #717b94;
          border: none;
          padding: 0px;
        "
        >account_circle</mat-icon
      >
    </button>
    <mat-menu #menu="matMenu" class="menu">
      <button
        mat-menu-item
        class="AccountMatMenuButton"
        style="font-style: italic; cursor: default !important"
      >
        {{ name }}
      </button>
      <p
        style="text-align: left; padding-left: 12px; padding-top: 10px"
        class="AccountMatMenuButton"
      >
        Build:{{ uiVersion }}
      </p>
      <button
        (click)="onCopyToken()"
        mat-menu-item
        class="AccountMatMenuButton"
      >
        API Token
      </button>
      <button (click)="logout()" mat-menu-item class="AccountMatMenuButton">
        Logout
      </button>
    </mat-menu>
  </div>
</div>
