import { OidcSecurityService } from 'angular-auth-oidc-client';
import { SubProcessType } from './../../models/reprocess-request';
import { ReprocessDialogComponent } from './../reprocess-dialog/reprocess-dialog.component';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { JobContainer, JobIdContainerResponse, JobState, Pagination, UnifiedJob } from '../../models';

import { ApiService } from '../../services/api.service';
import { SignalRService } from '../../services/signal-r.service';
import { KillProcessDialogComponent } from '../kill-process-dialog/kill-process-dialog.component';
import { ProgressSpinnerDialogComponent } from '../progress-spinner-dialog/progress-spinner-dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.scss'],
})
export class JobComponent implements OnInit {
  private dialog!: MatDialogRef<ProgressSpinnerDialogComponent> | null;

  jobs: UnifiedJob[] = [];
  pagination!: Pagination | null;
  selectedJobs: UnifiedJob[] = [];

  selectedNode!: UnifiedJob | null;
  uiVersion = environment.uiVersion

  // Jobcontainer Metrics
  jobIdContainerResponse: JobIdContainerResponse = {
    processorCount: 0,
    maxConcurrentJobs: 0,
    runningJobCount: 0,
    jobContainers: [],
    runningContainers: []
  };

  // pagination
  jobCount = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];

  constructor(private readonly _apiService: ApiService,
    private readonly _signalRService: SignalRService,
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly _matDialog: MatDialog) {
    this._signalRService.start();
  }

  ngOnInit(): void {
    this.dialog = this._matDialog.open(ProgressSpinnerDialogComponent, {
      id: 'ProgressSpinnerDialogComponent',
      disableClose: true,
    });

    this._apiService.getJobs().subscribe((response) => {
      this.jobs = response.data;
      this.pagination = response.pagination;

      this.jobCount = this.pagination.totalItemCount;
      this.pageSize = this.pagination.pageSize;

      setTimeout(() => this.dialog?.close(), 100);
    });

    this._apiService.getJobContainers().subscribe(response =>{
      this.jobIdContainerResponse = response;
    })

    this._signalRService.jobExReceived$.subscribe((msg) => {
      console.log('JobComponent:Job', msg);

      var idx = this.jobs.findIndex((j) => j.id === msg.id);

      if (idx == -1)
        this.jobs.unshift(msg);
      else 
        this.jobs[idx] = msg;
    });

    this._signalRService.subJobExReceived$.subscribe((msg) => {
      console.log('JobComponent:SubJob', msg);

      var idx = this.jobs.findIndex((j) => j.id === msg.jobId);

      if (idx == -1) return;

      const job = this.jobs[idx];

      this._addOrUpdateSubJob(msg, job);
    });

    this._signalRService.jobContainersReceived$.subscribe(msg => {
      console.log('JobContainerResponse', msg);

      this.jobIdContainerResponse = msg;
    });
  }

  onJobChange(id: string) {
    console.log(id);

    this.dialog = this._matDialog.open(ProgressSpinnerDialogComponent, {
      id: 'ProgressSpinnerDialogComponent',
      disableClose: true,
    });

    this._apiService.getJob(id).subscribe((job) => {
      if (!job) 
        this.selectedNode = null;
      else {
        this.selectedNode = job;

        setTimeout(() => this.dialog?.close(), 100);
      }
    });
  }

  onRetry(ev: UIEvent, job: UnifiedJob) {
    console.log('RETRY', job);

    ev.stopPropagation();

    if (job.filename)
      this._apiService.retryJob(job.filename).subscribe((t) => console.log(t));
    else console.error(`retry of job ${job.id} without filename`);
  }

  onKill(ev: UIEvent, job: UnifiedJob) {
    const dialogRef = this._matDialog.open(KillProcessDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
        console.log('KILL', job);
        ev.stopPropagation();
        this._apiService.killJob(job.id).subscribe((t) => console.log(t));
      } else console.log('Kill was cancelled');
    });
  }

  onReprocess($event: UIEvent, job: UnifiedJob) {

    console.log('onReprocess job', job);

    this._apiService.getJob(job.id).subscribe(result => {
      this._handleReprocessDialog(result);
      
    });
  }

  private _handleReprocessDialog(job: UnifiedJob) {
    const dialogRef = this._matDialog.open(ReprocessDialogComponent, {
      data: job
    });

    dialogRef.afterClosed().subscribe((result: SubProcessType) => {
      if (result !== SubProcessType.None)
        this._apiService.reprocess(job.filename, result).subscribe((response)=> console.log(response));
      else
        console.log("Reprocess has been cancelled");      
    });
  }

  onReprocessX(jobId: string, fileName: string) {
    const dialogRef = this._matDialog.open(ReprocessDialogComponent,{
      data: {
        jobId: jobId
      }
    });

    dialogRef.afterClosed().subscribe((result)=>{
      if (result!= false) {
        if (result[0]==true && result[1]==true && result[2]==true && result[3]==true) {
          this._apiService.reprocess(fileName, SubProcessType.All).subscribe((response)=> console.log(response));
        } 
        else{
          if (result[0]==true) {
            this._apiService.reprocess(fileName, SubProcessType.Transcoding).subscribe((response)=> console.log(response));
          }
          if (result[1]==true) {
            this._apiService.reprocess(fileName, SubProcessType.Thumbnail).subscribe((response)=> console.log(response));
          }
          if (result[2]==true) {
            this._apiService.reprocess(fileName, SubProcessType.Checksum).subscribe((response)=> console.log(response));
          }
          if (result[3]==true) {
            this._apiService.reprocess(fileName, SubProcessType.Subtitle).subscribe((response)=> console.log(response));
          }
        }
      } 
      else {
        console.log("Reprocess has been cancelled");
      }
      
    })
  }

  getPaginatorData($event: PageEvent) {
    console.log($event);

    this.dialog = this._matDialog.open(ProgressSpinnerDialogComponent, {
      id: 'ProgressSpinnerDialogComponent',
      disableClose: true,
    });

    this._apiService
      .getJobs($event.pageIndex + 1, $event.pageSize)
      .subscribe((response) => {
        this.jobs = response.data;
        this.pagination = response.pagination;

        this.jobCount = this.pagination.totalItemCount;
        this.pageSize = this.pagination.pageSize;
        setTimeout(() => this.dialog?.close(), 100);
      });
  }

  getJobStateClass(job: UnifiedJob) {
    switch (job.state) {
      case JobState.Created:
        return 'job-state-created';
      case JobState.Running:
        return 'job-state-running';
      case JobState.Completed:
        return 'job-state-completed';
      case JobState.Faulted:
        return 'job-state-faulted';
      default:
        return 'job-state-created';
    }
  }

  isRunning(job: UnifiedJob): boolean {
    return job.state === JobState.Running;
  }

  private _addOrUpdateSubJob(subJob: UnifiedJob, parent: UnifiedJob): boolean {
    if (parent.id === subJob?.parentId) {
      if (!parent.children) {
        parent.children = [];

        parent.children.push(subJob);

        return true;
      }

      for (let idx = 0; idx < parent.children.length; ++idx) {
        if (parent.children[idx].id === subJob.id) {
          parent.children[idx] = subJob;
          return true;
        }
      }

      parent.children.push(subJob);

      parent.children.sort((a, b) => {
        if (a.creationDate < b.creationDate) return -1;
        if (a.creationDate > b.creationDate) return 1;

        return 0;
      });

      return true;
    }

    if (!parent.children || parent.children.length === 0) return false;

    for (let idx = 0; idx < parent.children.length; ++idx) {
      if (parent.children[idx].id === subJob.id) {
        parent.children[idx] = subJob;
        return true;
      }
    }

    for (let idx = 0; idx < parent.children.length; ++idx) {
      if (this._addOrUpdateSubJob(subJob, parent.children[idx])) return true;
    }

    return false;
  }
}
function emptyJobIdContainerResponse(): JobIdContainerResponse {
  throw new Error('Function not implemented.')
}

