export const environment = {
    production: true,
    apibase: 'https://fp.ems.makalu.live/api',
    apiVersion: 'v1',
    wsbase: 'https://fp.ems.makalu.live/api',
    authorityUrl: 'https://auth.ems.makalu.live/auth',
    realm: 'makalu',
    clientId: 'file-processing-ui',
    uiVersion: '1.0.14'
};
